import { DateTime } from 'luxon';

export function formatCurrency(value) {
	if(value) {
		return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
	} else {
		return '';
	}
}

export function formatDateTime(dateTime) {
	if(dateTime === null) {
		return 'N/A';
	} else {
		return DateTime.fromISO(dateTime).toLocaleString(DateTime.DATETIME_SHORT);
	}
}

export function timeNotPassed(dateTime) {
	return DateTime.fromISO(dateTime).diffNow() > 0;
}

export function hasTodaysDate(dateTime) {
	return DateTime.now().toLocaleString(DateTime.DATE_SHORT) === DateTime.fromISO(dateTime).toLocaleString(DateTime.DATE_SHORT);
}

export default {
	formatCurrency,
	formatDateTime,
	hasTodaysDate,
	timeNotPassed
}

